
var defaultLat  = 39.074208;
var defaultLng  = 21.824311999999964;
var defaultZoom = 3;
var map         = false;

var loadOverlay = function(index){

	$marker   = map.markers[index];
	$position = $marker.getPosition();
	$lat      = $position.lat();
	$lng      = $position.lng();

	map.setCenter($lat, $lng);

	contenu = '<div class="map-overlay"><div class="overlay-content"><a href="#" class="close"></a><img src="'+$marker.img+'"><h6>'+$marker.nom+'</h6><p>'+$marker.ville+' / '+$marker.pays+'</p>';
	if( $marker.url != "" )
		contenu += "<p><a href='"+$marker.url+"' class='map-link'>"+$marker.urlText+"</a></p>";
	contenu += '</div></div>';

	map.removeOverlays();

	var imgLoad = imagesLoaded( $(contenu) );
	imgLoad.on( 'always', function() {
		map.drawOverlay({
			lat: $lat,
			lng: $lng,
			verticalAlign:'center',
			horizontalOffset:20,
			layer:'floatPane',
			content : contenu
		});
	});

	$(document).on('click touchstart', '.close', function(e) {
		e.preventDefault();
    	map.removeOverlays();
    });

}

var markersOEM = [];
var markersAOL = [];
var markersANT = [];

var stylez = [{"featureType":"poi","elementType":"all","stylers":[{"hue":"#000000"},{"saturation":-100},{"lightness":-100},{"visibility":"off"}]},{"featureType":"poi","elementType":"all","stylers":[{"hue":"#000000"},{"saturation":-100},{"lightness":-100},{"visibility":"off"}]},{"featureType":"administrative","elementType":"all","stylers":[{"hue":"#000000"},{"saturation":0},{"lightness":-100},{"visibility":"off"}]},{"featureType":"road","elementType":"labels","stylers":[{"hue":"#ffffff"},{"saturation":-100},{"lightness":100},{"visibility":"off"}]},{"featureType":"water","elementType":"labels","stylers":[{"hue":"#000000"},{"saturation":-100},{"lightness":-100},{"visibility":"off"}]},{"featureType":"road.local","elementType":"all","stylers":[{"hue":"#ffffff"},{"saturation":-100},{"lightness":100},{"visibility":"on"}]},{"featureType":"water","elementType":"geometry","stylers":[{"hue":"#ffffff"},{"saturation":-100},{"lightness":100},{"visibility":"on"}]},{"featureType":"transit","elementType":"labels","stylers":[{"hue":"#000000"},{"saturation":0},{"lightness":-100},{"visibility":"off"}]},{"featureType":"landscape","elementType":"labels","stylers":[{"hue":"#000000"},{"saturation":-100},{"lightness":-100},{"visibility":"off"}]},{"featureType":"road","elementType":"geometry","stylers":[{"hue":"#bbbbbb"},{"saturation":-100},{"lightness":26},{"visibility":"on"}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"hue":"#dddddd"},{"saturation":-100},{"lightness":-3},{"visibility":"on"}]}];


var loadMap = function(){

	map = new GMaps({
		div: '#map',
		zoom: defaultZoom,
		lat: defaultLat,
		lng: defaultLng,
		scrollwheel: false,
		zoomControl : true,
        panControl : false,
		options: {
			'styles': stylez
		}
	});

	// récupération de l'id si on pointe un marker en particulier
	var params = window.location.pathname.split('/').slice(3,4);
	var id = params[0];

	$.ajax({
		dataType: "json",
		type: "POST",
		data : "id="+parseInt(id),
		url: "/"+$('html').attr('lang')+"/references/",
		error: function(jqXHR, textStatus, errorThrown) {
			alert("Erreur");
		},
		success: function(data){

			$.each(data, function(i,reference){

				var image = new google.maps.MarkerImage('/images/reference/'+reference.icon+'@2x.png', null, null, null, new google.maps.Size(28,34));

				var element = map.addMarker({
					id:      reference.id,
					lat:     reference.lat,
					lng:     reference.lng,
					nom:     reference.nom,
					ville:   reference.ville,
					pays:    reference.pays,
					img:     reference.img,
					icon:    image,
					url:     reference.url,
					urlText: reference.urlText,
					click:   function(element){
						loadOverlay(i);
					}
				});

				if( reference.zoomIn == true ){

					map.setZoom(13);
					map.setCenter(reference.lat, reference.lng);
					element.setAnimation(google.maps.Animation.BOUNCE);

				}

				switch( reference.icon ){
					case 'oem':
			 			markersOEM.push(element);
						break;
					case 'aol':
			 			markersAOL.push(element);
						break;
					default:
			 			markersANT.push(element);
						break;
				}

			});

			var markerClustererOptionsANT = {    
				gridSize: 30,
				styles: [{
					url: '/images/reference/antares-brut@2x.png',
					width: 28,
					height: 34,
					textColor: '#ffffff',
					backgroundSize : "28px 34px"
				}]
			}; 

			var markerClustererOptionsOEM = {    
				gridSize: 30,
				styles: [{
					url: '/images/reference/oem-brut@2x.png',
					width: 28,
					height: 34,
					textColor: '#ffffff',
					backgroundSize : "28px 34px"
				}]
			};  

			var markerClustererOptionsAOL = {    
				gridSize: 30,
				styles: [{
					url: '/images/reference/aol-brut@2x.png',
					width: 28,
					height: 34,
					textColor: '#ffffff',
					backgroundSize : "28px 34px"
				}]
			}; 

			var markerClusterANT = new MarkerClusterer(map.map, markersANT, markerClustererOptionsANT);
			var markerClusterOEM = new MarkerClusterer(map.map, markersOEM, markerClustererOptionsOEM);
			var markerClusterAOL = new MarkerClusterer(map.map, markersAOL, markerClustererOptionsAOL);

    	}
	});

	GMaps.geolocate({
		success: function(position) {
			map.setCenter(position.coords.latitude, position.coords.longitude);
			map.setZoom(4);
		},
		error: function(error) {
			//alert('Geolocation failed: '+error.message);
		},
		not_supported: function() {
			alert("Your browser does not support geolocation");
		},
		always: function() {
			// alert("Done!");
		}
	});

}